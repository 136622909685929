import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const AccountPage = () => {
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      navigate('/404');
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return <></>;
};

export default AccountPage;
